import React, { useState, useEffect } from 'react';

// import { Form } from '@unform/web';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Container, Button, Typography, CircularProgress } from '@mui/material';
// custom Components
import Unform from 'src/components/form';

import { postUser, showUser, putUser } from 'src/services/usersService';

import { useSnackbar } from 'src/contexts/Snackbar';

import styles from './style';

const INPUTS = [
  {
    component: 'input',
    name: 'username',
    label: 'Login'
  },
  {
    component: 'input',
    name: 'email',
    label: 'E-mail'
  },
  {
    component: 'select',
    name: 'role',
    label: 'Cargo',
    values: [
      { label: 'Admin', value: 'admin' },
      { label: 'Vendedor', value: 'role' }
    ]
  },
  {
    component: 'input',
    name: 'password',
    label: 'Senha'
  }
];
function User() {
  const [user, setUser] = useState(null);
  const [loadding, setLoadding] = useState(false);
  const { id } = useParams();
  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    async function load() {
      const res = await showUser(id);
      setUser(res);
      console.log(res);

      setLoadding(false);
    }
    if (id) {
      setLoadding(true);
      load();
    }
  }, [id]);

  const handleSubmit = async (data) => {
    console.log(data);

    if (id) {
      const response = await putUser(id, data);
      console.log(response);
      setSnackbar('Usuário salvo com sucesso');
    } else {
      const response = await postUser(data);
      console.log(response);
      setSnackbar('Usuário criado com sucesso');
    }
  };
  return (
    <Container>
      <Paper>
        <Grid container sx={styles.container}>
          <Grid md={6} lg={6} xs={12}>
            <Typography variant="h3" sx={styles.container}>
              {id ? 'Editar ' : 'Adicionar '}
              User
            </Typography>
            {loadding && 'carregando...'}
            {((id && user) || !id) && (
              <span>
                <Unform form={INPUTS} obj={user} onSubmit={handleSubmit} initialData={user} />
                {!loadding && (
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    Salvar
                  </Button>
                )}
                {loadding && (
                  <Button variant="contained" color="primary" fullWidth>
                    <CircularProgress />
                  </Button>
                )}
              </span>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default User;
