/* eslint-disable  */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Button } from '@mui/material';
import TableSimple from 'src/components/table/Simple';
import { getOrganizations } from 'src/services/organizationService';
import { useHistory } from 'react-router-dom';
import CustomerListToolbar from './CustomerListToolbar';

const CustomerPage = () => {
  const [organizations, setOrganizations] = useState({
    total: 15,
    data: []
  });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    async function loadOrganizations() {
      try {
        const resp = await getOrganizations(1);
        setOrganizations(resp);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    loadOrganizations();
  }, []);
  const loadOrganizations = async (page) => {
    try {
      const resp = await getOrganizations(page);
      console.log(page);
      console.log(resp);
      setOrganizations(resp);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const search = async (text) => {
    const resp = await getOrganizations(text);
    setOrganizations(resp);
    setLoading(false);
  };

  const reload = async () => {
    const resp = await getOrganizations();
    setOrganizations(resp);
    setLoading(false);
  };

  const handleView = (id) => {
    history.push(`organization/${id}`);
  };
  const columns = [
    {
      label: 'ID',
      attribute: 'id'
    },
    {
      label: 'Nome',
      attribute: 'fantasy_name'
    },
    {
      label: 'CNPJ ',
      attribute: 'cnpj'
    },
    {
      label: 'Sites',
      attribute: 'countSites'
    },
    {
      label: 'Plano',
      attribute: 'Plan'
    },
    {
      label: 'Ação',
      attribute: 'id',
      type: 'custom',
      custom: (element) => <Button onClick={() => handleView(element)}>ver</Button>
    }
  ];
  return (
    <>
      <Helmet>
        <title>Clientes | CVO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar onLoad={search} reload={reload} />
          <Box sx={{ pt: 3 }}>
            <TableSimple
              data={organizations.data}
              total={organizations.total}
              columns={columns}
              loading={loading}
              functionFetchPage={(page) => loadOrganizations(page)}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerPage;
