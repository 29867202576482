import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Container, Grid } from '@mui/material';
import baseUrl from 'src/services/baseurl';
import moment from 'moment';
import styles from './styles';

function Edit({ data }) {
  return (
    <Paper>
      <Container sx={styles.editContainer}>
        <Typography variant="h3" component="h3" sx={styles.title}>
          Organização
        </Typography>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="body1">
              Status:
              {data.status ? 'Ativo' : 'Desativada'}
            </Typography>
            <Typography variant="body1">
              Nome:
              {` ${data.corporate_name}`}
            </Typography>
            <Typography variant="body1">
              Nome fantasia:
              {` ${data.fantasy_name}`}
            </Typography>
            <Typography variant="body1">
              CNPJ:
              {` ${data.cnpj}`}
            </Typography>

            <Typography variant="body1">
              Sundomínio:
              {` ${data.uuid}`}
            </Typography>
            <Typography variant="body1">
              Slogan:
              {` ${data.slogan}`}
            </Typography>
            <Typography variant="body1">
              Ativo:
              {` ${data.active ? 'Ativa' : 'Não ativa'}`}
            </Typography>
            <Typography variant="body1">
              Entrou em:
              {` ${moment(data.created_at).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography variant="body1">
              OrganizationId:
              {data?.id}
            </Typography>
            <Typography variant="body1">
              Entrou em:
              {` ${moment(data.created_at).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography variant="body1">
              <a
                href={`https://${data.uuid}.tudode.delivery`}
                target="_blank"
                rel="noreferrer"
              >
                {`Site do cliente: https://${data.uuid}.tudode.delivery`}
              </a>
            </Typography>
            <Typography variant="body1">
              PlayStore:
              {
                data?.playStoreUrl ? (
                  <a
                    href={`https://play.google.com/store/apps/details?id=com.cvo${data.uuid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {` https://play.google.com/store/apps/details?id=com.cvo${data.uuid}`}
                  </a>
                ) : ' Aplicativo insdiponível'
              }
            </Typography>
          </Grid>
          <Grid md={4}>
            {data.logo && <img alt="Logo organização" src={`${baseUrl}/${+data.logo}`} />}
          </Grid>
        </Grid>
        <Typography variant="h3" sx={styles.title}>
          Filiais
        </Typography>

        {data?.organization?.filials.map((filial) => (
          <div key={filial.id}>
            <Typography variant="body1">
              FilialId:
              {filial?.id}
            </Typography>
            <Typography variant="body1">
              Nome:
              {filial?.name}
            </Typography>
            <Typography variant="body1">
              Logo:
              {filial?.logo}
            </Typography>
          </div>
        ))}
      </Container>
    </Paper>
  );
}

Edit.propTypes = {
  data: PropTypes.object.isRequired
};

export default Edit;
