import { api } from 'src/contexts/Auth';

export async function getUserList() {
  const response = await api.get('/superadmin/user');
  return response.data;
}
export async function postUser(user) {
  const body = {
    username: user.username,
    uuid: user.username,
    password: user.password,
    email: user.email,
    admin: user.role === 'admin',
    role: user.role
  };
  const response = await api.post('/superadmin/user', body);
  return response.data;
}
export async function putUser(id, user) {
  const body = {
    username: user.username,
    uuid: user.username,
    password: user.password || user.password !== '' ? user.password : false,
    email: user.email,
    admin: user.role === 'admin',
    role: user.role
  };
  const response = await api.put(`/superadmin/user/${id}`, body);
  return response.data;
}
export async function showUser(id) {
  const response = await api.get(`/superadmin/user/${id}`);
  return response.data;
}

export default {
  getUserList
};
