import axios from 'axios';

import baseUrl from './baseurl';

export async function signIn(username, password) {
  const response = await axios.post(`${baseUrl}/superadmin/login`, {
    username,
    password
  });
  return response.data;
}

export default signIn;
