/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  // TextField,
  Typography,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';
import styles from './styles';

function AccountProfileDetails(props) {
  const [values, setValues] = useState(props.account);
  const [password, setPassword] = useState('');
  const [showPassword, setshowPassword] = useState(true);
  // const classes = useStyles();
  // const handleChange = (event) => {
  //   console.log(props.account);
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });

  //   console.log(values);
  // };

  return (
    <Card>
      <CardHeader subheader="" title="Minha Conta" />
      <Divider />
      <form autoComplete="off" noValidate {...props}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              {/* <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                defaultValue={values.email}
                variant="outlined"
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
              <Typography>{`Email: ${values.email}`}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <TextField
                fullWidth
                label="Username"
                name="username"
                onChange={handleChange}
                required
                value={values.username}
                variant="outlined"
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              /> */}

              <Typography>{`Username: ${values.username}`}</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography>
                Criado em: {moment(values.created_at).format('DD/MM/YYYY HH:MM')}
              </Typography>
              <Typography>
                Atualizado em: {moment(values.updated_at).format('DD/MM/YYYY HH:MM')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained">
            Salvar
          </Button>
        </Box> */}
        <Divider />
        <CardHeader subheader="" title="Alterar senha" />
        <Divider />
        <form autoComplete="off" noValidate {...props}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <FormControl sx={[styles.margin, styles.textField]} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowPassword(!showPassword)}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button color="primary" variant="contained" onClick={() => props.setPassword(password)}>
              Salvar
            </Button>
          </Box>
        </form>
      </form>
    </Card>
  );
}

export default AccountProfileDetails;
