// import { makeStyles } from '@mui/styles';

export default {
  container: {
    padding: 3,
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    marginTop: '8px !important',
    marginBottom: '8px !important'
  }
};
