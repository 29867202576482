import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
// custom components
import Card from 'src/components/dashboard/Card';
// import CardCurrency from 'src/components/dashboard/CardMoney';
// import CardPercent from 'src/components/dashboard/CardPercent';
import { useParams } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import { showOrganization } from 'src/services/organizationService';
import Plan from './Plan';
import Edit from './Edit';
// services

function TabOrganizationView() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    async function getData() {
      const response = await showOrganization(params.id);
      console.log(response);
      setData(response);
      setLoading(false);
    }
    getData();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Helmet>
          <title>Visualizar Cliente</title>
        </Helmet>
        {loading && 'Carregando...'}
        {!loading && data && (
          <Grid container justify="center" spacing={2}>
            <Grid item md={3} xs={12}>
              <Card value={data.customer_active} Icon={PeopleIcon} label="CLIENTES ATIVOS" />
            </Grid>
            <Grid item md={3} xs={12}>
              <Card value={data.monthly_billing} Icon={PeopleIcon} label="QTD VENDAS" />
            </Grid>
            <Grid item md={3} xs={12}>
              <Card value={data.total_products} Icon={PeopleIcon} label="QTD PRODUTOS" />
            </Grid>
            <Grid item md={3} xs={12}>
              <Card
                value={data.plan && data.plan.name ? data.plan.name : 'Sem plano'}
                Icon={PeopleIcon}
                label="PLANO"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Edit data={data.organization} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Plan plan={data.plan} idOrganization={data.organization.id} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}

export default TabOrganizationView;
