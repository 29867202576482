/* eslint-disable  */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import axios from 'axios';
import * as auth from 'src/services/authService';
import baseurl from 'src/services/baseurl';

const localStorageName = '@cvosuperadmin';

const AuthContext = createContext();

let api = null;
// let apiFormData = null;

/**
 * @param {*} children
 * @returns Component
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiContext, setApiContext] = useState(false);
  // iniciando state
  useEffect(
    useCallback(() => {
      async function load() {
        const storagedUser = await localStorage.getItem(
          `${localStorageName}:user`
        );
        const storagedToken = JSON.parse(
          await localStorage.getItem(`${localStorageName}:token`)
        );
        if (storagedUser && storagedToken) {
          console.log(storagedToken);
          setUser(JSON.parse(storagedUser));

          api = axios.create({
            baseURL: baseurl,
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${storagedToken.token}`
            }
          });
          setApiContext(api);
        }
        setLoading(false);
      }
      load();
    }, []),
    []
  );

  function setAuth(response) {
    setUser(response.user);
    api = axios.create({
      baseURL: baseurl,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${response.token.token}`
      }
    });
    setApiContext(axios);
    localStorage.setItem(
      `${localStorageName}:user`,
      JSON.stringify(response.user)
    );
    localStorage.setItem(
      `${localStorageName}:token`,
      JSON.stringify(response.token)
    );
  }

  /**
   * @description login
   * @param {*} username
   * @param {*} password
   */
  async function signIn(username, password) {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await auth.signIn(username, password);
      setAuth(response);
      console.log(response);
    } catch (err) {
      setErrorMessage('Usuário ou senha incorretos');
    }
    setLoading(false);
  }

  /**
   * @description logout
   */
  async function signOut() {
    setUser(null);
    await localStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        loading,
        errorMessage,
        api: apiContext
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { api };

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
