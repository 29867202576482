import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import Card from 'src/components/dashboard/Card';
import CardCurrency from 'src/components/dashboard/CardMoney';
import CardPercent from 'src/components/dashboard/CardPercent';
// import LatestOrders from 'src/components/dashboard/LatestOrders';
// import LatestProducts from 'src/components/dashboard/LatestProducts';
// import Sales from 'src/components/dashboard/Sales';
// import TrafficByDevice from 'src/components/dashboard/TrafficByDevice';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import { getCardAshboard } from 'src/services/dashboardService';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';

const Dashboard = () => {
  const [dataCard, setDataCard] = useState(false);
  const [loadingCard, setLoadingCard] = useState(true);

  useEffect(() => {
    async function loadCard() {
      try {
        const card = await getCardAshboard();
        setLoadingCard(false);
        setDataCard(card);
      } catch (err) {
        console.log(err.message);
      }
    }
    loadCard();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {!loadingCard && (
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <CardCurrency value={dataCard && dataCard.budget && dataCard.budget.value} Icon={MoneyIcon} label="GANHO MENSAL" />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Card
                  value={dataCard && dataCard.customers && dataCard.customers.value}
                  Icon={PeopleIcon}
                  label="TOTAL CLIENTES ATIVOS"
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <CardPercent
                  value={dataCard && dataCard.development && dataCard.development.value}
                  Icon={InsertChartIcon}
                  label="DESENVOLVIMENTO"
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <CardCurrency value={dataCard && dataCard.profit && dataCard.profit.value} Icon={MoneyIcon} label="GANHO TOTAL" />
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
