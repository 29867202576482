import React, { useState, useEffect } from 'react';

import {
  Grid,
  Paper,
  Container,
  // Button,
  Typography,
  // TextField,
  // FormControl,
  // IconButton,
  // InputAdornment
  // OutlinedInput,
  // InputLabel
} from '@mui/material';
// custom Components
import FormInputs from 'src/components/form';

import { postOrganization } from 'src/services/organizationService';

import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'src/contexts/Snackbar';
import { getPlanList } from 'src/services/planService';
import styles from './style';
import INPUTS from './schema';

function CustomerAdd() {
  const [loadding, setLoadding] = useState(false);
  const { setSnackbar } = useSnackbar();
  const history = useHistory();

  const [schema, setSchema] = useState([]);

  const handleSubmit = async (data) => {
    setLoadding(true);

    await postOrganization({
      organization: {
        fantasy_name: data.fantasy_name,
        corporate_name: data.corporate_name,
        slogan: data.slogan,
        name: data.name,
        cnpj: data.cnpj,
        ie: data.ie,
        plan: data.plan,
        uuid: data.uuid
      },
      filial: {
        name: data.corporate_name,
        cnpj: data.cnpj,
        fantasy_name: data.fantasy_name,
        coporate_name: data.corporate_name,
        slogan: data.slogan
      },
      user: {
        username: data.username,
        email: data.email,
        password: data.password,
        displayName: data.displayName,
        fullName: data.fullName,
        cpf: data.cpf
      }
    })
      .then((res) => {
        console.log(res);
        setSnackbar('Usuário salvo com sucesso');
        history.push(`/organization/${res?.organization?.id}`);
      })
      .catch((err) => {
        // alert(err.message);
        if (err && err.response && err.response.data && err.response.data.message) {
          console.log(err.response.data.message);
        }
      });

    setLoadding(false);
  };
  useEffect(() => {
    (async () => {
      const responsePlans = await getPlanList();
      const optionsPlans = responsePlans.map((plan) => ({
        value: plan.id,
        label: plan.name
      }));
      setSchema(INPUTS(optionsPlans));
    })();
  }, []);
  return (
    <Container>
      <Paper>
        <Grid container sx={styles.container}>
          <Grid md={6} lg={6} xs={12}>
            <Typography variant="h3" sx={styles.container}>
              Adicionar cliente
            </Typography>
            {loadding && 'carregando...'}
            <FormInputs form={schema} initialData={{}} onSubmit={handleSubmit} loading={loadding} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default CustomerAdd;
