import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

function SnackbarComponent({ open, setOpen, message }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={() => (
          <span>
            <Button color="secondary" size="small" onClick={handleClose}>
              X
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        )}
      />
    </div>
  );
}

SnackbarComponent.propTypes = {
  open: PropTypes.any,
  message: PropTypes.string,
  setOpen: PropTypes.func
};
export default SnackbarComponent;
