import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { useHistory } from 'react-router-dom';
import TableSimple from 'src/components/table/Simple';
import { getPlanList } from 'src/services/planService';
import CustomerListToolbar from './PlanListToolbar';

const columns = [
  {
    label: 'ID',
    attribute: 'id'
  },
  {
    attribute: 'name',
    label: 'Nome'
  },
  // {
  //   attribute: 'product_types',
  //   label: 'Produtos',
  //   type: 'custom'
  //   // custom: (item) =>  item
  // },
  {
    attribute: 'maximum_number_of_filials',
    label: 'Núm. filiais'
  },
  {
    attribute: 'maximum_job_number',
    label: 'Núm. usuários'
  }
  // {
  //   attribute: 'issue_invoice',
  //   label: 'Nota Fiscal',
  //   type: 'boolean'
  // }
];

const PlanPage = () => {
  const [plans, setPlan] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function load() {
      try {
        const data = await getPlanList();
        setPlan(data);
        setTotalItems(data.length);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    load();
  }, []);

  const fething = async (pageItem, perPage) => {
    try {
      const { data, total } = await getPlanList(pageItem, perPage);
      setPlan(data);
      setTotalItems(total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const search = async (text) => {
    const resp = await getPlanList(text);
    setPlan(resp);
    setLoading(false);
  };

  const reload = async () => {
    const resp = await getPlanList();
    setPlan(resp);
    setLoading(false);
  };

  const onEditRow = (id) => {
    console.log(id);
    history.push(`/plans/${id}`);
  };
  return (
    <>
      <Helmet>
        <title>Clientes | CVO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar onLoad={search} reload={reload} />
          <Box sx={{ pt: 3 }}>
            <TableSimple
              data={plans}
              columns={columns}
              loading={loading}
              handleEdit={onEditRow}
              total={totalItems}
              functionFetchPage={fething}
              noPagination
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PlanPage;
