import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  // Button,
  Divider,
  Drawer,
  Hidden,
  List
  // Typography
} from '@mui/material';
import AuthContext from 'src/contexts/Auth';
import {
  // AlertCircle as AlertCircleIcon,
  Home as HomeIcon,
  Users as UsersIcon,
  User as UserIcon,
  File as FileIcon
} from 'react-feather';

import NavItem from './NavItem';

const items = [
  {
    href: '/',
    icon: HomeIcon,
    title: 'Dashboard'
  },
  {
    href: '/organization',
    icon: UsersIcon,
    title: 'Clientes'
  },
  {
    href: '/plans',
    icon: FileIcon,
    title: 'Planos',
    role: 'admin'
  },
  {
    href: '/users',
    icon: UserIcon,
    title: 'Usuários',
    role: 'admin'
  },
  {
    href: '/account',
    icon: UserIcon,
    title: 'Minha Conta'
  }
  // {
  //   href: '/resports',
  //   icon: BarChartIcon,
  //   title: 'Relatórios'
  // },

  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (item.role) {
              if (user.role === item.role) {
                return (
                  <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
                );
              }
              return <></>;
            }
            return (
              <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
