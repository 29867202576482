import { useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  IconButton
} from '@mui/material';
import { Search as SearchIcon, RotateCw } from 'react-feather';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const PlanListToolbar = ({ onLoad, reload, ...props }) => {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      onLoad(e.target.value);
    }
  };
  const handleReload = () => {
    reload();
    setSearch('');
  };
  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container direction="row">
              <Grid item md={8} direction="row">
                <Grid container direction="row">
                  <Grid item md={11} direction="row">
                    <TextField
                      fullWidth
                      value={search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Pesquisar"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={1} spacing={10} align="right">
                    <IconButton>
                      <SvgIcon fontSize="small" color="action" onClick={handleReload}>
                        <RotateCw />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} spacing={10} align="right">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push('/addPlan')}
                >
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

PlanListToolbar.propTypes = {
  onLoad: PropTypes.func,
  reload: PropTypes.func
};

export default PlanListToolbar;
