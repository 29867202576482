import * as yup from 'yup';

const INPUTS = (plans) => [
  {
    component: 'input',
    name: 'fantasy_name',
    label: 'Nome fantasia',
    yup: yup.string('Nome fantasia').required('Nome fantasia é obrigatório')
  },
  {
    component: 'input',
    name: 'corporate_name',
    label: 'razão social',
    yup: yup.string('Razão social').required('Razão social é obrigatório')
  },
  {
    component: 'input',
    name: 'cnpj',
    label: 'CNPJ',
    yup: yup.string('cnpj').required('cnpj é obrigatório')
  },
  {
    component: 'input',
    name: 'ie',
    label: 'Inscrição estadual',
    yup: yup.string('Inscrição estadual, não é obrigatório')
  },
  {
    component: 'input',
    name: 'slogan',
    label: 'slogan',
    yup: yup.string('Slogan, a principal frase da empresa').required('Obrigatório')
  },
  {
    component: 'select',
    name: 'plan',
    label: 'Plano',
    options: plans,
    yup: yup.string('Plano de pagamento da empresa para nos como empresa')
  },
  {
    component: 'input',
    name: 'uuid',
    label: 'SubDominio do site ( sem espaço )',
    yup: yup
      .string('Subdomino, nome da empresa sem espaço')
      .required(
        'Obrigatório, pode ser o nome da empresa sem espaço, e não pode existir em nosso banco'
      )
  },
  {
    component: 'input',
    name: 'username',
    label: 'Login ( sem espaço )',
    yup: yup.string('Login ( sem espaço )').required('Nome do  usuário para login')
  },
  {
    component: 'input',
    name: 'email',
    label: 'Email',
    yup: yup.string('Enter your email').email('email inválido').required('Email é obrigatório')
  },
  {
    component: 'password',
    name: 'password',
    label: 'Senha',
    yup: yup
      .string('Digite a senha da conta')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .required('Senha é obrigatório')
  },
  {
    component: 'input',
    name: 'displayName',
    label: 'Nome exibido',
    yup: yup.string('Nome exibido').required('Primeiro nome obrigatório')
  },
  {
    component: 'input',
    name: 'fullName',
    label: 'Nome completo',
    yup: yup.string('Nome completo').required('Nome completo obrigatório')
  },
  {
    component: 'input',
    name: 'cpf',
    label: 'CPF',
    yup: yup.string('CPF').required('cpf obrigatório')
  }
];

export default INPUTS;
