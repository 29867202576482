import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';
import { useSnackbar } from 'src/contexts/Snackbar';

import { getPlanList } from 'src/services/planService';
import { putOrganzation } from 'src/services/organizationService';
import formatMoney from 'src/utils/money';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300
  },
  formControl: {
    margin: 1,
    minWidth: 120
  }
};

function DialogSelect({ open, setOpen, idOrganization }) {
  const [plan, setPlan] = useState('');

  const { setSnackbar } = useSnackbar();

  const [listPlans, setListPlans] = useState([]);

  useEffect(() => {
    async function load() {
      const res = await getPlanList();

      setListPlans(res);
    }
    load();
  }, []);

  const handleChange = (event) => {
    setPlan(Number(event.target.value) || '');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async () => {
    console.log({ plan }, plan);
    const res = await putOrganzation(idOrganization, { plan });
    if (res) {
      setSnackbar('Plano salvo com sucesso');
      handleClose();
    } else {
      setSnackbar('Houve algun erro');
      console.log(res);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Alterar plano </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Alterar plano da empresa </DialogTitle>
        <DialogContent>
          <Box sx={styles.container}>
            <FormControl sx={styles.formControl} fullWidth variant="outlined">
              <InputLabel id="demo-dialog-select-label">Plano</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={plan}
                onChange={handleChange}
                input={<Input />}
              >
                {listPlans?.map((planIndex) => (
                  <MenuItem value={planIndex.id} key={planIndex.id}>
                    {planIndex.name}
                    {formatMoney(+planIndex.price)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogSelect.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  idOrganization: PropTypes.any
};

export default DialogSelect;
