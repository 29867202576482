// import { makeStyles } from '@mui/material';

export default {
  select: {
    paddingTop: 2,
    width: '100%'
  },
  root: {
    marginTop: 2,
    marginBottom: 2
  }
};
