import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { useHistory } from 'react-router-dom';
import TableSimple from 'src/components/table/Simple';
import { getUserList } from 'src/services/usersService';
import ListToolbar from './ListToolbar';

const columns = [
  {
    label: 'ID',
    attribute: 'id'
  },
  {
    attribute: 'username',
    label: 'Nome'
  },
  {
    attribute: 'email',
    label: 'E-mail'
  },
  {
    attribute: 'role',
    label: 'Cargo'
  }
];

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function load() {
      try {
        const resp = await getUserList();
        setUsers(resp);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    load();
  }, []);

  const search = async (text) => {
    const resp = await getUserList(text);
    setUsers(resp);
    setLoading(false);
  };

  const reload = async () => {
    const resp = await getUserList();
    setUsers(resp);
    setLoading(false);
  };

  const onEditRow = (id) => {
    console.log(id);
    history.push(`/users/${id}`);
  };
  return (
    <>
      <Helmet>
        <title>Clientes | CVO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar onLoad={search} reload={reload} />
          <Box sx={{ pt: 3 }}>
            <TableSimple data={users} columns={columns} loading={loading} handleEdit={onEditRow} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UsersPage;
