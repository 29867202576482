import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Paper, Container, Typography } from '@mui/material';
// custom Components
import FormInputs from 'src/components/form';
import { postPlan, showPlan, putPlan } from 'src/services/planService';
import { useSnackbar } from 'src/contexts/Snackbar';
import styles from './style';
import INPUTS from './inputs';

function Plan() {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setfetchLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    async function load() {
      const res = await showPlan(id);
      console.log(res);
      setLoading(false);
      setPlan(res);
    }
    if (id) {
      setLoading(true);
      load();
    }
  }, [id]);

  const handleSubmit = async (data) => {
    const playload = {
      ...data,
      type_of_sale: data.type_of_sale.split(','),
      product_types: data.product_types.split(',')
    };
    setfetchLoading(true);
    if (id) {
      await putPlan(id, playload);
      setSnackbar('Plano salvo com sucesso');
    } else {
      const response = await postPlan(playload);
      console.log(response);
      setSnackbar('Plano criado com sucesso');
      history.push(`/plans/${response.id}`);
    }
    setfetchLoading(false);
  };
  return (
    <Container>
      <Paper>
        <Grid container sx={styles.container}>
          <Grid md={6} lg={6} xs={12}>
            <Typography variant="h3" sx={styles.container}>
              {id ? 'Editar ' : 'Adicionar '}
              Plano
            </Typography>
            {loading && 'carregando...'}
            {((id && plan) || !id) && (
              <>
                {/* <form onSubmit={handleSubmit} initialData={plan}> */}
                <FormInputs
                  form={INPUTS}
                  initialData={plan || {}}
                  onSubmit={handleSubmit}
                  loading={fetchLoading}
                />
                <Typography variant="body2" sx={styles.container}>
                  {`Stripe ID do produto: ${plan?.stripe_product_id}`}
                </Typography>
                <Typography variant="body2" sx={styles.container}>
                  {` Stripe ID do preço: ${plan?.stripe_price_id}`}
                </Typography>
                {/* </form> */}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Plan;
