import React, { useState } from 'react';
// import { useField } from "@unform/core";
// import { TextField } from 'unform-material-ui';
import { TextField, Box, InputAdornment, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import styles from './style';

const IconPassword = ({ showPassword, setshowPassword }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="Clique para mostrar a senha"
      onClick={() => setshowPassword(!showPassword)}
      onMouseDown={(event) => event.preventDefault()}
      edge="end"
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);
IconPassword.propTypes = {
  showPassword: PropTypes.bool,
  setshowPassword: PropTypes.func
};
function Input({ name, label, type = 'text', register, password, error }) {
  // const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const passwordProps = {};
  if (password) {
    passwordProps.endAdornment = (
      <IconPassword setshowPassword={setshowPassword} showPassword={showPassword} />
    );
  }

  return (
    <Box sx={styles.root}>
      <TextField
        size="small"
        variant="outlined"
        label={label}
        fullWidth
        error={error}
        helperText={error}
        type={type}
        {...register(name)}
        {...passwordProps}
      />
    </Box>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  password: PropTypes.bool,
  register: PropTypes.func.isRequired
};

export default Input;
