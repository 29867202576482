/* eslint-disable  */
import React, { createContext, useContext, useState } from 'react';
import Snackbar from 'src/components/Snackbar';

const SnackbarContext = createContext();

/**
 * @param {*} children
 * @returns Component
 */
export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const setSnackbar = (message, snackbar) => {
    setOpen(true);
    setMessage(message);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        message,
        setSnackbar
      }}
    >
      <Snackbar open={open} message={message} setOpen={setOpen} />
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export default SnackbarContext;
