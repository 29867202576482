import React from 'react';
// import { Select } from 'unform-material-ui';
import { MenuItem, Select, Box, FormControl, InputLabel, FormHelperText } from '@mui/material';
// import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import styles from './style';

const Input = ({ name, options, defaultValue, register, error, label }) => (
  <Box sx={styles.root}>
    <FormControl fullWidth size="small">
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        variant="outlined"
        fullWidth
        sx={styles.select}
        error={error}
        label={label}
        helperText={error}
        defaultValue={defaultValue}
        {...register(name)}
      >
        {options?.map((v) => (
          <MenuItem value={v.value}>{v.label}</MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  </Box>
);

Input.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  options: PropTypes.array,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func.isRequired
};

export default Input;
