import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import { showUser, putUser } from 'src/services/usersService';
import { useAuth } from 'src/contexts/Auth';
import { useSnackbar } from 'src/contexts/Snackbar';
import AccountProfile from './AccountProfile';
import AccountProfileDetails from './AccountProfileDetails';

const Account = () => {
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    async function load() {
      const response = await showUser(auth.user.id);
      setAccount(response);
      setLoading(false);
    }
    load();
  }, []);
  const handleSetPassword = async (password) => {
    setLoading(true);
    console.log(password);
    if (password === '') {
      setLoading(false);
      setSnackbar('Definda uma senha');
      return;
    }
    try {
      await putUser(account.id, { ...account, password });
      const response = await showUser(auth.user.id);
      setSnackbar('Senha atualizada');
      setAccount(response);
    } catch (err) {
      setSnackbar('houve um erro ao atualizar');
    }
    setLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Conta</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {loading && 'Carregando...'}
            {!loading && account && (
              <>
                <Grid item lg={4} md={6} xs={12}>
                  <AccountProfile account={account} />
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                  <AccountProfileDetails account={account} setPassword={handleSetPassword} />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
