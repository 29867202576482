import * as yup from 'yup';

const INPUTS = [
  {
    component: 'input',
    name: 'name',
    label: 'Nome',
    yup: yup.string('Nome').required('Nome é obrigatório')
  },
  {
    component: 'input',
    name: 'price',
    label: 'Preço ( opcional )',
    yup: yup
      .number('deve ser um numero')
      .typeError('Valor deve ser um número')
      .min(0, 'Valor minimo é 0')
      .max(3000, 'Valor máximo é 3000.')
  },
  {
    component: 'select',
    name: 'payment_time',
    label: 'Tempo entre os pagamentos',
    options: [{ label: 'Mensal - Cartão de crédito', value: 30 }],
    yup: yup.string('Nome').required('Escolha tipo')
  },
  {
    component: 'select',
    name: 'type_of_payment',
    label: 'Tempo de cobrança',
    // multiple: true,
    options: [
      { label: 'Manual', value: 'manual' },
      { label: 'Cartão de crédito', value: 'stripe' }
    ],
    yup: yup.string('Nome').required('Escolha tipo')
  },
  {
    component: 'selectMultiple',
    name: 'type_of_sale',
    label: 'Tipo de cobrança',
    multiple: true,
    options: [
      { label: 'Na Entrega', value: 'in_delivery' },
      { label: 'Mercado pago', value: 'mercado_pago' },
      { label: 'Pag Seguro', value: 'pagseguro' }
    ],
    yup: yup.string('Nome').required('Escolha tipo')
  },
  {
    component: 'selectMultiple',
    name: 'product_types',
    label: 'Tipo de produto vendido no plano',
    multiple: true,
    options: [
      { label: 'Produto simple', value: 'product_simple' },
      { label: 'Comida simples', value: 'food_simple' },
      { label: 'Produto com atributo', value: 'product_atribute' }
    ],
    yup: yup.string('Nome').required('Escolha tipo')
  },
  {
    component: 'slider',
    name: 'manual_cashier_access',
    label: 'Acesso ao caixa manual( Em breve )'
  },
  // {
  //   component: 'slider',
  //   name: 'subcategory',
  //   label: 'Colocar subcategorias'
  // },
  // {
  //   component: 'input',
  //   name: 'maximum_number_of_filials',
  //   label: 'Número máximo de filiais',
  //   type: 'number'
  // },
  {
    component: 'input',
    name: 'maximum_job_number',
    label: 'Número máximo de usuários',
    type: 'number',
    yup: yup.number('Nome').required('Defina um valor')
  }
  // {
  //   component: 'slider',
  //   name: 'issue_invoice',
  //   label: 'Emitir Nota Fiscal( Em breve )'
  // }
];

export default INPUTS;
