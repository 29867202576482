import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
// import moment from 'moment';
// import getInitials from 'src/utils/getInitials';

const MenuItemComponent = ({ handleEdit, item, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`fade-menu-${item.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {handleEdit && item.id && (
          <MenuItem
            onClick={() => {
              console.log(item.id, 'handleEdit');
              handleEdit(item.id);
            }}
          >
            Editar
          </MenuItem>
        )}
        {handleDelete && item.id && (
          <MenuItem onClick={() => handleDelete(item.id)}>Deletar</MenuItem>
        )}
      </Menu>
    </>
  );
};
MenuItemComponent.propTypes = {
  item: PropTypes.object,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

const TableSimple = ({
  data,
  columns,
  handleEdit,
  onClickRow,
  handleDelete,
  functionFetchPage,
  noPagination = false,
  total,
  ...rest
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
    await functionFetchPage(newPage + 1);
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    handlePageChange(event, 1);
  };
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((i) => (
                  <TableCell key={i.label}>{i.label}</TableCell>
                ))}
                {(handleEdit || handleDelete) && <TableCell>Ações</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* .slice(page * limit, page * limit + limit) */}
              {data.map((item) => (
                <TableRow hover key={item.id} onClick={() => onClickRow && onClickRow(item)}>
                  {columns.map((c) => {
                    if (c.type) {
                      switch (c.type) {
                        case 'boolean':
                          return (
                            <TableCell key={c.label}>
                              <Typography>{item[c.attribute] ? 'verdadeiro' : 'falso'}</Typography>
                            </TableCell>
                          );
                        case 'custom':
                          return <TableCell key={c.label}>{c.custom(item[c.attribute])}</TableCell>;
                        default:
                          return (
                            <TableCell key={c.label}>
                              <Typography>{item[c.attribute]}</Typography>
                            </TableCell>
                          );
                      }
                    }
                    return (
                      <TableCell key={c.label}>
                        <Typography>{item[c.attribute]}</Typography>
                      </TableCell>
                    );
                  })}
                  {(handleEdit || handleDelete) && (
                    <TableCell>
                      <MenuItemComponent
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        item={item}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {!noPagination && (
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Card>
  );
};

TableSimple.propTypes = {
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  onClickRow: PropTypes.func,
  noPagination: PropTypes.bool,
  functionFetchPage: PropTypes.func
};

export default TableSimple;
