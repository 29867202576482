import { api } from 'src/contexts/Auth';

export async function getPlanList(page = 1, perPage = 10) {
  const response = await api.get('/superadmin/plan', {
    params: {
      page,
      perPage
    }
  });
  return response.data;
}

export async function showPlan(id) {
  const response = await api.get(`/superadmin/plan/${id}`);
  return response.data;
}

export async function postPlan(plan) {
  const response = await api.post('/superadmin/plan', plan);
  return response.data;
}
export async function putPlan(id, plan) {
  const response = await api.put(`/superadmin/plan/${id}`, plan);
  return response.data;
}

export default getPlanList;
