/* eslint-disable  */
import React from 'react';
import { Box } from '@mui/material';
import Input from './Input';
import Currency from './Currency';
import Slider from './Slider';
import Password from './Password';
import Select from './Select';
import SelectMultiple from './SelectMultiple';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

const stylesHere = {
  root: {
    margin: 1,
    padding: 2
  }
};

const generateYupSchema = (form) => {
  const objectSchema = {};
  for (let input of form) {
    if (input.yup) {
      objectSchema[input.name] = input.yup;
    }
  }
  const validation = yup.object(objectSchema);
  return validation;
};

const FormInputs = (props) => {
  const { form, onSubmit, initialData, loading } = props;

  const schemaGenerated = generateYupSchema(form);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: initialData || {},
    resolver: yupResolver(schemaGenerated)
  });
  console.log('erros', errors);
  return (
    // <UnformObjectProvider>
    <Box sx={stylesHere.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {form.map((input, index) => {
          switch (input.component) {
            // case 'scope':
            //   return (
            //     <Scope
            //       name={input.name}
            //       inputs={input.inputs}
            //       label={input.label}
            //       key={index}
            //       obj={obj}
            //     />
            //   );
            case 'select':
              return (
                <Select
                  key={index}
                  register={register}
                  control={control}
                  error={errors?.[input.name]?.message}
                  defaultValue={initialData[input.name]}
                  {...input}
                />
              );
            case 'selectMultiple':
              return (
                <SelectMultiple
                  key={index}
                  setValue={setValue}
                  register={register}
                  error={errors?.[input.name]?.message}
                  control={control}
                  defaultValue={initialData[input.name]}
                  {...input}
                />
              );
            case 'input':
              return (
                <Input
                  key={index}
                  register={register}
                  error={errors?.[input.name]?.message}
                  {...input}
                />
              );
            case 'password':
              return (
                <Password
                  key={index}
                  register={register}
                  error={errors?.[input.name]?.message}
                  {...input}
                />
              );
            case 'currency':
              return (
                <Currency
                  key={index}
                  register={register}
                  error={errors?.[input.name]?.message}
                  setValue={setValue}
                  {...input}
                />
              );
            case 'slider':
              return (
                <Slider
                  key={index}
                  register={register}
                  control={control}
                  error={errors?.[input.name]?.message}
                  {...input}
                />
              );
            default:
              return <span>Input não cadastrado</span>;
          }
        })}

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Salvar
        </LoadingButton>
      </form>
    </Box>
    // </UnformObjectProvider>
  );
};

export default FormInputs;
