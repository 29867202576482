import React, { useState } from 'react';
import { Paper, Typography, Container, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import formatMoney from 'src/utils/money';

import SetPlanModal from './SetPlanModal';
import styles from './styles';

function Plan({ plan, idOrganization }) {
  const [open, setOpen] = useState(false);
  return (
    <Paper sx={styles.rootPlan}>
      <Container>
        <Typography variant="h3" sx={styles.title}>
          Plano
        </Typography>
        {plan && (
          <Card>
            <CardContent sx={styles.cardPlan}>
              <Typography variant="h4">{`${plan?.name}`}</Typography>
              <Typography variant="h6">{formatMoney(+plan?.price)}</Typography>
            </CardContent>
          </Card>
        )}
        {!plan && (
          <>
            <Typography variant="h6">Não há um plano atribuido a esta conta</Typography>
          </>
        )}
        <SetPlanModal open={open} setOpen={setOpen} idOrganization={idOrganization} />
      </Container>
    </Paper>
  );
}
Plan.propTypes = {
  plan: PropTypes.object,
  idOrganization: PropTypes.any
};
export default Plan;
