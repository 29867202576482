/* eslint-disable  */
import React, { useContext } from 'react';
import {
  // BrowserRouter as Router,
  // Switch,
  Route
  // Link,
  // Redirect
  // useHistory,
  // useLocation
} from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
// import MainLayout from 'src/components/MainLayout';
// import Account from 'src/pages/Account';
import CustomerList from 'src/pages/Customer';
import CustomerView from 'src/pages/CustomerView';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login/Login';
import NotFound from 'src/pages/NotFound';
import PlanList from 'src/pages/Plan';
import Account from 'src/pages/Account';
import PlanView from 'src/pages/Plan/Plan';
import CustomerAdd from 'src/pages/CustomerAdd';
import Users from 'src/pages/Users';
import UsersView from 'src/pages/Users/User';
// import ProductList from 'src/pages/ProductList';
// import Register from 'src/pages/Register';
// import Settings from 'src/pages/Settings';
import AuthContext from 'src/contexts/Auth';

// import { useAuth } from 'src/contexts/Auth';

const RoutesApp = () => (
  <DashboardLayout>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/organization" component={CustomerList} />
    <Route exact path="/organizationAdd" component={CustomerAdd} />
    <Route exact path="/organization/:id" component={CustomerView} />
    <Route exact path="/plans" component={PlanList} />
    <Route exact path="/addPlan" component={PlanView} />
    <Route exact path="/plans/:id" component={PlanView} />
    <Route exact path="/account" component={Account} />
    <Route exact path="/user" component={UsersView} />
    <Route exact path="/users" component={Users} />
    <Route exact path="/users/:id" component={UsersView} />
    {/* <Route path="*" ><NotFound /></Route> */}
  </DashboardLayout>
);
const RoutesPublic = () => (
  <Route path="/">
    <Login />
  </Route>
);

export default function Routes() {
  // const routingApp = useRoutes(routesApp);

  // const routingAuth = useRoutes(routesPublic);

  const { user, loading } = useContext(AuthContext);

  return !loading && user ? <RoutesApp /> : <RoutesPublic />;
}
