import React, { useEffect } from 'react';
// import ReactInputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
// import { useField } from '@unform/core';

import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
/* eslint-disable  */
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, defaultValue, ...other } = props;

  return (
    <>
      <p>{defaultValue}</p>
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        defaultValue={defaultValue}
        // value={defaultValue}
        thousandSeparator
        isNumericString
        prefix="R$"
      />
    </>
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
function InputMask({ label, register, defaultValue, name, error, setValue, ...props }) {
  // const inputRef = useRef(null);
  // const { fieldName, registerField, defaultValue } = useField(name);
  useEffect(() => {
    register(name);
  }, []);
  const handleChange = (event) => {
    setValue(name, event.target.value);
  };
  return (
    // <NumberFormat
    //   ref={inputRef}
    //   defaultValue={defaultValue}
    //   thousandsGroupStyle="lakh"
    //   getInputRef={inputRef}
    //   // decimalSeparator=","
    //   // thousandSeparator="."
    //   label={label}
    //   decimalScale={2}
    //   fixedDecimalScale
    //   customInput={(props) => <CustomInput error={error} {...props} />}
    //   onValueChange={(values) => {
    //     setValue(name, values.value);
    //   }}
    // />
    <TextField
      label={label}
      // value={}
      onChange={handleChange}
      defaultValue={defaultValue}
      name={name}
      id={`${name}-currency`}
      error={error}
      helperText={error}
      fullWidth
      InputProps={{
        inputComponent: (props) => <NumberFormatCustom defaultValue={defaultValue} {...props} />
      }}
      variant="outlined"
      {...props}
    />
  );
}
InputMask.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func,
  defaultValue: PropTypes.any,
  setValue: PropTypes.func
};

export default InputMask;
