import React from 'react';
// import { Select } from 'unform-material-ui';
import { Box, FormHelperText, TextField, Autocomplete } from '@mui/material';
// import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import styles from './style';

const Input = ({ name, register, options, defaultValue, error, label, setValue }) => {
  const handleChange = (value) => {
    // const {
    //   target: { value }
    // } = event;
    console.log(value);
    setValue(name, value);
  };
  register(name);
  const getOptionLabel = (option) => {
    if (option.label) return option.label;
    let value = false;
    options.forEach((opt) => {
      if (opt.value === option) {
        value = opt.label;
      }
    });
    return value || 'Não encontrado';
  };
  return (
    <Box sx={styles.root}>
      {/* <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          sx={styles.select}
          error={error}
          input={<OutlinedInput label={label} defaultValue={defaultValue} />}
          label={label}
          helperText={error}
          defaultValue={defaultValue}
          // {...register(name)}
          multiple
          value={inputValue}
          onChange={handleChange}
        >
          {options?.map((v) => (
            <MenuItem value={v.value}>{v.label}</MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl> */}
      <Autocomplete
        multiple
        id={`${name}-select-multiple`}
        options={options.map((opt) => opt.value)}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label={label} placeholder={label} />}
        onChange={(e, v) => handleChange(v)}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  // icon: PropTypes.any,
  label: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.array,
  error: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

export default Input;
