/* eslint-disable  */
import { useState } from 'react';
// import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import SnackBar from './Snackbar';
const DashboardLayoutRoot = {
  backgroundColor: 'default ', // theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
};

const DashboardLayoutWrapper = {
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 9,
  // [theme.breakpoints.up('lg')]: {
  paddingLeft: 32
  // }
};

const DashboardLayoutContainer = {
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
};

const DashboardLayoutContent = {
  flex: '1 1 auto',
  height: '100wh',
  overflow: 'auto'
};

const DashboardLayout = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box sx={DashboardLayoutRoot}>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box sx={DashboardLayoutWrapper}>
        <Box sx={DashboardLayoutContainer}>
          <Box sx={DashboardLayoutContent}>
            <SnackBar />
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
