import {
  Avatar,
  Box,
  // Button,
  Card,
  // CardActions,
  CardContent,
  // Divider,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const AccountProfile = ({ account, ...props }) => (
  <Card {...props}>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={account.avatar}
          sx={{
            height: 100,
            width: 100
          }}
        />
        <Typography color="textPrimary" gutterBottom variant="h3">
          {account.displayName}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {account.username}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {account.fullName}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {`Cargo: ${account.role}`}
        </Typography>
      </Box>
    </CardContent>
    {/* <Divider /> */}
    {/* <CardActions>
      <Button
        color="primary"
        fullWidth
        variant="text"
      >
        Upload picture
      </Button>
    </CardActions> */}
  </Card>
);

AccountProfile.propTypes = {
  account: PropTypes.object.isRequired
};

export default AccountProfile;
