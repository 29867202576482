import { api } from 'src/contexts/Auth';

export async function getOrganizations(page = 1, search) {
  const params = {};
  if (search) {
    params.search = search;
  }

  params.page = page;

  const response = await api.get('/superadmin/organization', {
    params
  });
  return response.data;
}
export async function showOrganization(id) {
  const response = await api.get(`/superadmin/organization/${id}`);
  return response.data;
}

export async function putOrganzation(id, data) {
  const response = await api.put(`/superadmin/organization/${id}`, data);
  return response.data;
}

export async function postOrganization(data) {
  const response = await api.post('/superadmin/organization', data);
  return response.data;
}
export default {
  getOrganizations
};
