export default {
  rootPlan: {
    padding: 2
  },
  editContainer: {
    paddingTop: 2,
    paddingBottom: 2
  },
  title: {
    paddingTop: 2,
    paddingBottom: 2
  },
  cardPlan: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
