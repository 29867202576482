import React from 'react';
// import { Switch } from 'unform-material-ui';
import { Switch, Box, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
// import { useField } from '@unform/core';
import PropTypes from 'prop-types';

const Input = ({ name, control, label, defaultValue = false, error = false }) => (
  <Box isErrored={!!error}>
    {/* <FormControlLabel
      control={<Switch checked={defaultValue} color="primary" {...register(name)} />}
      label={label}
    /> */}
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      // render={({ field }) => <Switch {...field} />}
      render={({ field }) => (
        <FormControlLabel
          control={<Switch color="primary" {...field} />}
          label={label}
          {...field}
        />
      )}
    />
  </Box>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  error: PropTypes.bool,
  control: PropTypes.any.isRequired,
  label: PropTypes.string
};

export default Input;
