import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@mui/material';
// import GlobalStyles from 'src/components/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import Routes from 'src/routes';

import { AuthProvider } from 'src/contexts/Auth';
import { SnackbarProvider } from 'src/contexts/Snackbar';

const App = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      {/* <GlobalStyles /> */}
      <CssBaseline />
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default App;
